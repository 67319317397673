<template>
    <div class="p-2">
      <ButtonPrimary
        v-if="!thread && canStartThread"
        class=""
        text="Back to Messages"
        @click="startThread"
        dusk="start-chat"
      >
        Begin Chat Thread
      </ButtonPrimary>

      <ButtonSecondary
        v-if="canParticipate"
        @click="participate"
        class="m-2"
        dusk="join-chat"
      >
        Participate in Existing Chat Thread
      </ButtonSecondary>

      <div
        v-if="thread && isParticipating"
        class="relative"
        dusk="thread"
      >
        <FontAwesomeIcon
          v-if="isOpen"
          @click="isOpen = !isOpen"
          :icon="faClose"
          class="h-6 absolute top-1.5 right-1.5 text-blue-400 cursor-pointer"
          title="minimize"
          dusk="close-chat"
        />

        <h2 class="text-gray-900 text-xl">
          <span v-if="isOpen">
            Chat Between:
          </span>
          <span
            v-else @click="isOpen = !isOpen"
            class="cursor-pointer text-blue-500 hover:text-blue-400"
            dusk="open-chat"
          >
            Open Chat Between:
          </span>

        </h2>

        <div class="flex" dusk="participants">
          <Participant
            :participant="recipient"
            :user="user"
            :canOptOut="false"
          />
          <Participant
            v-for="participant in thread.participants"
            :key="participant.id"
            :participant="participant"
            :user="user"
            :canOptOut="thread.participants.length > 1
                        && participant.uuid !== user.uuid
                        "
            @onOptOut="optOut"
          />
        </div>

        <div
          ref="messageContainer"
          class="mx-4 my-4 h-96 bg-gray-100 rounded-xl overflow-auto flex flex-col-reverse"
          v-if="isOpen"
        >
          <div>
            <Message
              v-for="message in thread.messages"
              :key="message.id"
              :message="message"
              :user="user"
            />
          </div>
        </div>

        <div class="relative" v-if="isOpen">
          <input
            @keyup.enter="sendMessage(newMessage)"
            dusk="new-message"
            v-model="newMessage"
            type="text"
            class="w-full border border-gray-300 p-2 pr-8 rounded-lg"
            placeholder="Type your message here..."
          />
          <button
            @click="sendMessage(newMessage)"
            :disabled="sendDisabled"
            dusk="send-message"
            class="absolute top-2 right-2 text-blue-500 hover:text-gray-400"
          >
            <FontAwesomeIcon
              :icon="faPaperPlane"
              class="h-6"
            />
          </button>
        </div>
      </div>


    </div>
</template>

<script setup>
import { computed, ref, defineProps, onMounted, nextTick } from 'vue';
import axios from 'axios';
import ButtonPrimary from '../ButtonPrimary.vue';
import ButtonSecondary from '../ButtonSecondary.vue';
import Participant from './Participant.vue';
import Message from './Message.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faPaperPlane } from '@fortawesome/pro-thin-svg-icons';
import { faClose } from '@fortawesome/pro-regular-svg-icons';

const props = defineProps({
  thread: {
    required: true
  },
  recipient:{
    required: true
  },
  user: {
    type: Object,
    required: true
  }
});

const newMessage = ref('');
const thread = ref(props.thread);
const recipient = ref(props.recipient);
const messageContainer = ref(null);
const sendDisabled = ref(false);
const isOpen = ref(false);

onMounted(() => {
  getThread();
  listenForUser();
  listenForThread();
});
const canStartThread = computed(() => {
  return props.user.type === 'admin' ||
         props.user.type === 'recruiter';
});

const isParticipating = computed(() => {
  if(!thread.value) return;

  const participants = thread.value.participants || [];

  const isAParticipant = participants
    .some(participant => participant.uuid === props.user.uuid);

  const isRecipient = recipient.value.uuid === props.user.uuid;

  return isAParticipant || isRecipient;

});

const canParticipate = computed(() => {
  const notRecipient = recipient.value.uuid !== props.user.uuid;

  return isParticipating.value === false && notRecipient;
});

async function sendMessage(message){
  if(message === '') return;

  newMessage.value = '';
  sendDisabled.value = true;

  await axios.post(
    `/messages/${thread.value.id}/message`,
    {body: message}
  );

  getThread();

  sendDisabled.value = false;
}

async function startThread(){
  let {data} = await axios.post(
      `/messages/${recipient.value.type}/${recipient.value.id}`,
    );

  thread.value = data;
  recipient.value = data.recipient;
  await getThread();
  await listenForThread();
}

async function getThread(){
  if(!thread.value) return;
  const response = await axios.get(`/messages/${thread.value.id}`);
  thread.value = response.data;
  if(thread.value.hasUnreadMessages){
    isOpen.value = true;
  }
  nextTick(() => {
    scrollToBottom()
  });
}

async function participate(){
  const {data} = await axios.post(
    `/messages/${thread.value.id}/participate`
  );
  getThread();
}

async function optOut(){
  const {data} = await axios.post(
    `/messages/${thread.value.id}/optout`
  );

  thread.value = data;
}

function listenForThread(){
  if(!thread.value) return;
  Echo.private(`threads.${thread.value.id}`)
    .listen('MessageSentEvent', () => {
      getThread();
    });
}

function listenForUser(){
  Echo.private(`App.User.${props.user.uuid}`)
    .listen('MessageThreadCreatedEvent', (data) => {
      thread.value = data.thread
      recipient.value = data.recipient;
      getThread();
      listenForThread();
    });
}

function scrollToBottom(){
  if(!messageContainer.value) return;
  messageContainer.value
    .scroll({
      top: messageContainer.value.scrollHeight,
      behavior: 'smooth'
    });
}

</script>
